import React, { Component } from 'react';
import HeaderBottom from '../components-global/Header-bottom';
import homedata from '../../data/homedata.json';


class Blog2col extends Component {

   render() {
      let publicUrl = process.env.PUBLIC_URL
      let imgattr = 'image'
      let propsData = this.props.data
      let headerPageTitle = propsData.pagetitle.singleblogtitle
       return (
         
         <section id="ourValues" className="p-80px-tb gray-bg" style={{minHeight:'100vh'}}>
         <div className="container p-80px-t">
            <div className="row">
               <div className="col-sm-8 offset-sm-2 ">
                  <div className="section-title text-center m-50px-b">
                     <h2>{homedata.howitworks.sectiontitle}</h2>
                  </div>
               </div>
            </div>
            <div className="row">
               { homedata.howitworks.features.map( ( feature, i ) => {
                  return <div key={i} className="col-lg-4 col-md-6 col-xs-12 d-flex align-items-stretch">
                        <div className="how-it-box text-center p-15px-lr p-50px-tb m-10px-t m-10px-b ">
                           <i className={feature.icon}></i>
                           <h4>{feature.title}</h4>
                           <p>{feature.content}</p>
                        </div>
                     </div>
                 } )
               }
            </div>
         </div>
      </section>
   
       );

       
     }

     
   }

export default Blog2col;
