import React, { Component } from 'react';
import homedata from '../../data/homedata.json';
import Event from './news';

class Pricing extends Component {
  render() {
    return (
      <section id="news" className="p-100px-tb parallax bg-overlay opacity-5" style={{backgroundImage: 'url(./assets/img/pricing.jpg)', minHeight:'100vh'}}>
         <div className="container p-80px-t">
            {
               <Event/>
            }
         </div>
      </section>
    );
  }
}

export default Pricing;
