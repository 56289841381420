import React, { Component } from 'react';
import Sidebar from './sidebar';
import Shareicon from './share-icon';
import Relatedpost from './related-posts';
import Comments from './comments';
import HeaderBottom from '../components-global/Header-bottom';
import Header2 from "../components-global/Header2";

class Singlerightsidebar extends Component {

  render() {
   let anchor = '#'
   let imgattr = 'image'
   let publicUrl = process.env.PUBLIC_URL
   let propsData = this.props.data
   let blogsdata = propsData.singlepostdata 
   let headerPageTitle = propsData.pagetitle.singlerightsidebartitle

    return (
      <div>
      <Header2 />
      {/* <HeaderBottom headertitle={headerPageTitle} /> */}
      <section id="blog-full" className="p-80px-tb gray-bg m-100px-t">
         <div className="container">
         <div className="row">
            {/* Content */}
            <div className="col-lg-8">
               <div className="single-blog-content page-content">
                  <div className="blog-post-img">
                        <img src="/assets/img/blog/MoU-Banner.png" alt="" />
                  </div>
                  <div className="blog-content">
                     <h3 className="m-20px-b">Memorandum of Understanding (MoU)</h3>
                     {/* <div className="post-meta m-15px-b">
                     <ul>
                        <li><a href={anchor}><i className="icofont-user-alt-5"></i> {blogsdata.author}</a></li>
                        <li><a href={anchor}><i className="icofont-tag"></i> {blogsdata.category}</a></li>
                        <li><a href={anchor}><i className="icofont-calendar"></i> {blogsdata.date}</a></li>
                        <li><a href={anchor}><i className="icofont-speech-comments"></i> {blogsdata.comments}</a></li>
                     </ul>
                     </div> */}
                     <div className="single-blog-post" style={{textAlign:'justify'}}>
                        <p>The Standard of International Islamic E-Trade Association (SIIETA) sealed a Memorandum of Understanding (MoU) to enhance strategic collaboration between its Technology Partners, Halal Initiatives Partners and e-sertu Operators on the 1st of September 2023 at Mines International Exhibition & Convention Centre (MIECC) during the Malaysia Halal Expo.  </p>
                        <p>The pact for Technology Partners collaboration was signed by Datuk Seri Mohd Rizal bin Mohd Yusuf (President of SIIETA) and Mr. Mohamed Hazli bin Mohamed Hussain (CEO of DagangTEK Sdn Bhd). </p>
                        <div className="row m-15px  m-50px-t">
                           <img src={publicUrl + '/assets/img/blog/Siieta-Dtek.jpg' } alt={imgattr} />
                           <p>Collaboration between SIIETA and Technology Partner.</p>
                        </div>
                        <p>As for Halal Initiatives Partners the MoU were signed by Datuk Seri Mohd Rizal bin Mohd Yusuf (President of SIIETA) and Mr. Zahari Azar Zainudin (SVP of DagangTEK Sdn Bhd), Dato’ Seri Muhamad Khanafi Husin (Chairman of Dewan Perdagangan Islam Malaysia (DPIM) Putrajaya), Dato' Hj. Khairul Shahril Bin Hamzah (CEO of HQC Commerce Sdn Bhd), Datin Seri Lusiana Susanty (Director of Euro Jasmine Holding Sdn Bhd) and Mr. Renutaasan A/L Rajan (Director of EJazmine Sdn Bhd). </p>
                        <div className="row m-15px m-50px-t">
                           <img src={publicUrl + '/assets/img/blog/MoU-Org.jpg' } alt={imgattr} />
                           <p>Collaboration between SIIETA and Halal Initiatives Partners.</p>
                        </div>
                        <p>This SIIETA strategic collaboration also tied a pact with its e-sertu operators which were signed by Datuk Seri Mohd Rizal bin Mohd Yusuf (President of SIIETA) and Mr. Mohamad Azri Bin Husin (Managing Director of Global Dibagh Sdn Bhd) and Mrs. Sartika Dewi Binti Sahat (CEO of Artiq (M) Sdn Bhd).</p>
                        <div className="row m-15px m-50px-t">
                           <img src={ publicUrl + '/assets/img/blog/Siieta-Operators.jpg'} alt={imgattr}/>
                           <p>Collaboration between SIIETA and e-sertu Operators.</p>
                        </div>
                        <p>These pacts will pave the way for all parties to embark on collaborative efforts and initiatives in facilitating trade electronically through international standards and guidelines by following Islamic principles.</p>
                        {/* <div className="row m-15px-t">
                           <div className="col-lg-6 col-md-12">
                              <img src={publicUrl + '/assets/img/blog/MoU-Org.jpg' } alt={imgattr} />
                              <p>Carriage quitting securing be appetite it declared.</p>
                           </div>
                           <div className="col-lg-6 col-md-12">
                              <img src={ publicUrl + '/assets/img/blog/Siieta-Operators.jpg'} alt={imgattr}/>
                              <p>Reasonable unpleasing an attachment my considered.</p>
                           </div>
                        </div> */}
                        {/* <h3>Use securing confined his shutters Delightful as he it acceptance an</h3>
                        <blockquote>Carriage quitting securing be appetite it declared. High eyes kept so busy feel call in. Would day nor ask walls known. But preserved advantage are but and certainty earnestly enjoyment. Passage weather as up am exposed. And natural related man subject. Eagerness get situation his was delighted. </blockquote>
                        <p>Lose eyes get fat shew. Winter can indeed letter oppose way change tended now. So is improve my charmed picture exposed adapted demands. Received had end produced prepared diverted strictly off man branched. Known ye money so large decay voice there to. Preserved be mr cordially incommode as an. He doors quick child an point at. Had share vexed front least style off why him. </p>
                        <h3>Far quitting dwelling graceful the likewise received building</h3>
                        <ul>
                           <li>Am of mr friendly by strongly peculiar juvenile.</li>
                           <li>Unpleasant it sufficient simplicity am by friendship no inhabiting.</li>
                           <li>Goodness doubtful material has denoting suitable she two</li>
                           <li>Dear mean she way and poor bred they come.</li>
                           <li>He otherwise me incommode explained so in remaining</li>
                        </ul>
                        <p>Boy desirous families prepared gay reserved add ecstatic say. Replied joy age visitor nothing cottage. Mrs door paid led loud sure easy read. Hastily at perhaps as neither or ye fertile tedious visitor. Use fine bed none call busy dull when. Quiet ought match my right by table means. Principles up do in me favourable affronting. Twenty mother denied effect we to do on. </p> */}
                     </div>
                  </div>
               </div>

               {/* {
               // Social Share icon 
               <Shareicon />
               }   */}
               {/* {
               // Related Post
               <Relatedpost />
               }   */}
               {/* {
               // Comments Area 
               <Comments /> 
               }  */}
            </div>
            {/* Sidebar */}
            <Sidebar />
         </div>
         </div>
      </section>
      </div>
    );
  }
}

export default Singlerightsidebar;
