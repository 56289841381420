import React, { Component } from 'react';
import Relatedpost from './related-posts';
import HeaderBottom from '../components-global/Header-bottom-sertu';
import Header2 from "../components-global/Header2";

class Singleblog extends Component {

  render() {
   let imgattr = 'image'
   let publicUrl = process.env.PUBLIC_URL
   let propsData = this.props.data
   let blogsdata = propsData.singlepostdata
   let headerPageTitle = propsData.pagetitle.singleblogtitle
  
    return (
      <div>
         <Header2 />
         <HeaderBottom headertitle={headerPageTitle} />
         
         <section id="blog-full" className="p-80px-tb gray-bg">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">

                     <div className="single-blog-content page-content">
                        <div className="blog-post-img">
                           <img style={{width: "1440px"}} src={ publicUrl + blogsdata.image} alt={imgattr}/>
                        </div>
                        <div className="blog-content">
                           <h1 className="m-20px-b">{blogsdata.title}</h1>
         
                           <div className="single-blog-post " style={{textAlign:'justify'}}>
                              <p>In Islamic Sharia law, Sertu is a cleansing process that "purifies" any type of surface contaminated by heavily impure substances (Najis Mughallazah). It has been proven that the sertu process promotes hygienic solutions and prevents potential harm to humans due to inherent disease caused by heavy impurities and derivatives. The Sertu process 100% adheres to the methods recommended by Prophet Muhammad (peace be upon him), as conveyed through several narrations from his companions. The Sertu process requires the use of soil mixed with water, emphasizing on the organized and disciplined washing with water throughout the entire process</p>
                              <a className="read-more" href="/assets/img/blog/What-is-Sertu-20230815v0.pdf" target="_blank" style={{borderRadius:"30px"}}>More About Sertu</a>
                              <h3 className="m-40px-t">Introducing e-sertu</h3>
                              <p>e-sertu is a digital platform which enable users to search for certain services online, e-sertu platform offers a wide range of sertu services, which is sertu cleansing, sertu training, sertu consulting and sertu competency assessment.</p>
                              <p>This platform enables users to apply for sertu services from a registered sertu operators adhering to their needs and preferences. It's also act as e-marketplace for sertu products, as well as an information hub which offers educational materials related to sertu. Our main objective is to globally connect stakeholders in the Islamic cleansing sertu ecosystem. We ensure all individuals, industries, institutions, operators and vendors are able to offer purchase and track the progress of sertu service requests online.</p>
                              <h3 className="m-40px-t">e-sertu Certification</h3>
                              <p>Experience the profound effects of Sertu and embrace the transformative power of Sertu cleansing. Join us on a journey of cleansing, purification, and self-discovery. Allow the timeless tradition of Sertu to guide you toward a life of spiritual fulfillment and harmony.</p>
                              <div className="row m-15px-t" style={{justifyContent:"center"}}>
                                 
                                 <div className="col-lg-6 col-md-12">
                                    <img src={publicUrl + '/assets/img/blog/certificate2.png' } alt={imgattr}/>
                                    {/* <p style={{textAlign:"center"}}>Vendor B</p> */}
                                 </div>
                              </div>
                              
                           </div>
                           
                        </div>
                     </div>

                     {
                     // Social Share icon 
                     // <Shareicon />
                     }  
                     {
                     // // Related Post
                     <Relatedpost />
                     }  
                     {/* {
                     // // Comments Area 
                     <Comments /> 
                     }                 */}

                  </div>
               </div>
            </div>
         </section>
      </div>
    );
  }
}

export default Singleblog;
